@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Neue Montreal';
  src: url('https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/6495a165b7188b7b3e901360_PPNeueMontreal-Regular.woff2?alt=media&token=98f71b70-76b4-4701-abb4-009dd81eeb3a') format('woff2');
}

/* base styles */
html{scroll-behavior:smooth}
.gm-style iframe + div { border:none!important; }
body {
  font-family: 'Neue Montreal', sans-serif!important;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 12px;
  
  scrollbar-color: #004457!important;
  background: #1f9751;
}

.react-modal-sheet-scroller {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.react-modal-sheet-scroller::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #004457;
}

::-webkit-scrollbar-thumb {
background-color: #004457!important;
outline: 1px solid #004457;
scrollbar-color: #004457;
}

.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}
.accordion-row:hover{
  background-color: #f8f8f8
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgb(177, 177, 177);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #3d3d3d;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

.rdw-dropdown-selectedtext{
  margin: 0!important;
}
.gm-style-iw-c:hover{
z-index: 600!important;
cursor: pointer;
box-shadow: 1px 1px 1px 1px rgba(200,200,200,0.5);
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.offerCard {
  animation: fadeIn 0.7s
}

.gradient-background {
  background: linear-gradient(237deg,#eb553c,#f7bdaf,#fc3f1e)!important;
  background-size: 180% 180%;
  animation: gradient-animation 9s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.gm-style-iw {
  padding: 8px 0 0 8px!important
}
.gm-style-iw-d {
  padding: 0px 8px 8px 0px !important;
  overflow: hidden!important
}
.design-process-section .text-align-center {
  line-height: 25px;
  margin-bottom: 12px;
}
.design-process-content {
  border: 1px solid #e9e9e9;
  position: relative;
  padding: 16px 34% 30px 30px;
}
.design-process-content img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-height: 100%;
}
.design-process-content h3 {
  margin-bottom: 16px;
}
.design-process-content p {
  line-height: 26px;
  margin-bottom: 12px;
}
.process-model {
  list-style: none;
  padding: 0;
  position: absolute;
  /* width: 500px; */

  /* margin: 20px auto 26px; */
  border: none;
  z-index: 0;
  /* display: "flex";
  justify-content: space-between; */
}
.process-model li::after {
  background: #e5e5e5 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  margin: 0 auto;
  position: absolute;
  /* right: -30px; */
  right: -50px;
  left:-50px;
  top: 26px;
  width: 600px;
  z-index: -1;
}
.process-model-big li::after {
  background: #e5e5e5 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  margin: 0 auto;
  position: absolute;
  /* right: -30px; */
  right: -30px;
  left:-30px;
  top: 40px;
  width: 600px;
  z-index: -1;
}
.process-model li.visited::after {
  background: rgb(0, 68, 87)!important;
  /* margin-left: -30px */
}
.process-model li:last-child::after {
  width: 0;
}
.process-model li {
  display: inline-block;
  /* width: 8%; */
  text-align: center;
  float: none;
  width: 120px;
}
.nav-tabs.process-model > li.active > a, .nav-tabs.process-model > li.active > a:hover, .nav-tabs.process-model > li.active > a:focus, .process-model li a:hover, .process-model li a:focus {
  border: none;
  background: transparent;

}
.process-model li a {
  padding: 0;
  border: none;
  color: #939393;
}
.process-model li.active,
.process-model li.visited {
  color: rgb(0, 68, 87);
}
.process-model li.active a,
.process-model li.active a:hover,
.process-model li.active a:focus,
.process-model li.visited a,
.process-model li.visited a:hover,
.process-model li.visited a:focus {
  color: rgb(0, 68, 87);
}
.process-model li.active p,
.process-model li.visited p {
  font-weight: normal;
}
.process-model li i {
  display: block;
  height: 48px;
  width: 48px;
  text-align: center;
  margin: 0 auto;
  background: white;
  border: 1px dashed #b3b3b3;
  
  line-height: 55px;
  font-size: 30px;
  border-radius: 50%;
}

.process-model-big li i {
  display: block;
  height: 78px;
  width: 78px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7;
  background-color: #f5f6f7;
  border: 1px dashed #b3b3b3;
  
  line-height: 105px;
  font-size: 30px;
  border-radius: 50%;
  transition: background-color 1.0s ease-in-out;
}

.process-model li.active i, .process-model li.visited i  {
  background: #fff;
  border:1px solid rgb(0, 68, 87);
}
.process-model li p {
  font-size: 14px;
  margin-top: 6px;
}

.process-model.contact-us-tab li.visited a, .process-model.contact-us-tab li.visited p {
  color: #606060!important;
  font-weight: normal
}
.process-model.contact-us-tab li::after  {
  display: none; 
}
.process-model.contact-us-tab li.visited i {
  border-color: #e5e5e5; 
  
}





@media screen and (max-width: 560px) {
.more-icon-preocess.process-model li span {
      font-size: 23px;
      height: 50px;
      line-height: 46px;
      width: 50px;
  }
  .more-icon-preocess.process-model li::after {
      top: 24px;
  }
}
@media screen and (max-width: 380px) { 
  .process-model.more-icon-preocess li {
      width: 16%;
  }
  .more-icon-preocess.process-model li span {
      font-size: 16px;
      height: 35px;
      line-height: 32px;
      width: 35px;
  }
  .more-icon-preocess.process-model li p {
      font-size: 8px;
  }
  .more-icon-preocess.process-model li::after {
      top: 18px;
  }
  .process-model.more-icon-preocess {
      text-align: center;
  }
}



.vertical-timeline::before {
  /* background: #e5e5e5 !important; */
  /* box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.3) !important; */
  width: 4px !important;
  height: 87% !important;
}

.timeline-grey-line::before{
  background: #e5e5e5 !important
}

.custom-timeline::before {
  background: white!important;
}

.vertical-timeline-item-title {
  color: rgb(0, 0, 0) !important;
  font-weight: normal;
  font-size: 1.2em;
}

.vertical-timeline {
  padding: 0 !important;
}

.vertical-timeline-element-content {
  background: #fff !important;
  -webkit-box-shadow: none !important;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.3) !important;
  border-radius: 7px !important;
}

.chat-timeline .vertical-timeline-element  {
  padding-left: 80px;
}

.chat-timeline .vertical-timeline-element-content  {
  width: 320px!important
}

.custom-timeline  .vertical-timeline-element-icon {
  -webkit-box-shadow: none !important;
  /* box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.3) !important; */
  background-color: #f5f6f7!important;
  color: #606060!important;
  border: 2px solid #e5e5e5!important;
  left: 80%!important;
  margin-left: 0!important;
  border: 0!important;
}

 .vertical-timeline-element-icon {
  -webkit-box-shadow: none !important;
  /* box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.3) !important; */
  background-color: #f5f6f7!important;
  color: #606060!important;
  border: 2px solid #e5e5e5!important;
  
}

.vertical-timeline-element-icon svg {
  width: 30px !important;
  height: 30px !important;
  margin-top: -15px !important;
  margin-left: -15px !important;
}

.vertical-timeline-description {
  display: none;
}

.vertical-timeline-description-inv {
  display: block;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid white !important;
}

.white-button{
  background-color: white!important;
}